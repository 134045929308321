import style from './TeachersComponent.module.css';

import koreanTitle from 'assets/korean/teacher/title.png';
import koreanTeacher1 from 'assets/korean/teacher/1.png'
import koreanTeacher2 from 'assets/korean/teacher/2.png'
import koreanTeacher3 from 'assets/korean/teacher/3.png'
import koreanTeachers1 from 'assets/korean/teacher/teachers1.png'
import koreanTeachers2 from 'assets/korean/teacher/teachers2.png'
import koreanAchievement from 'assets/korean/teacher/achievement.png';

import japanTitle from 'assets/japan/teacher/title.png';
import japanTeacher1 from 'assets/japan/teacher/1.png'
import japanTeacher2 from 'assets/japan/teacher/2.png'
import japanTeacher3 from 'assets/japan/teacher/3.png'
import japanTeachers1 from 'assets/japan/teacher/teachers1.png'
import japanTeachers2 from 'assets/japan/teacher/teachers2.png'


const Title = (props) => {
    const { type } = props;
    const isJapan = type === 'japan';
    return (
        <div className={style.container}>
            <img className={style.title} src={isJapan ? japanTitle : koreanTitle} alt='' />

            <img className={style.teacher} src={isJapan ? japanTeacher1 : koreanTeacher1} alt='' />
            <img className={style.teacher} src={isJapan ? japanTeacher2 : koreanTeacher2} alt='' />
            <img className={style.teacher} src={isJapan ? japanTeacher3 : koreanTeacher3} alt='' />



            {!isJapan &&
                <div className={style.teachers_box}>
                    <div className={style.korean_teachers_box1}>
                        <img src={koreanTeachers1} alt="" />
                    </div>
                    <div className={style.korean_teachers_box2}>
                        <img src={koreanTeachers2} alt="" />
                    </div>
                </div>
            }

            {isJapan &&
                <div className={style.teachers_box}>
                    <div className={style.japan_teachers_box1}>
                        <img src={japanTeachers1} alt="" />
                    </div>
                    <div className={style.japan_teachers_box2}>
                        <img src={japanTeachers2} alt="" />
                    </div>
                </div>
            }

            {!isJapan && <img className={style.teacher} src={koreanAchievement} alt='' />}
        </div>
    );
};

export default Title;