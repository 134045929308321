import Title from 'component/title/TitleComponent';
import Transfer from 'component/transfer/TransferComponent';
import Footer from 'component/footer/FooterComponent';

import style from './AboutComponent.module.css';

import AboutChild from './component/aboutChild/AboutChildComponent';
import Education from './component/education/EducationComponent';

import value1 from 'assets/about/2/1.png'
import value2 from 'assets/about/2/2.png'
import value3 from 'assets/about/2/3.png'
import value4 from 'assets/about/2/4.png'
import value5 from 'assets/about/2/5.png'

import job1 from 'assets/about/3/jobs/1.png'
import job2 from 'assets/about/3/jobs/2.png'
import job3 from 'assets/about/3/jobs/3.png'
import job4 from 'assets/about/3/jobs/4.png'

import hover_job1 from 'assets/about/3/jobs/1_hover.png'
import hover_job2 from 'assets/about/3/jobs/2_hover.png'
import hover_job3 from 'assets/about/3/jobs/3_hover.png'
import hover_job4 from 'assets/about/3/jobs/4_hover.png'


import welfareTitle from 'assets/about/3/welfare/title.png'

import culture1 from 'assets/about/culture/1.png'
import culture2 from 'assets/about/culture/2.png'
import culture3 from 'assets/about/culture/3.png'
import culture4 from 'assets/about/culture/4.png'
import culture5 from 'assets/about/culture/5.png'

import welfare1 from 'assets/about/3/welfare/1.png'
import welfare2 from 'assets/about/3/welfare/2.png'
import welfare3 from 'assets/about/3/welfare/3.png'
import welfare4 from 'assets/about/3/welfare/4.png'
import welfare5 from 'assets/about/3/welfare/5.png'
import welfare6 from 'assets/about/3/welfare/6.png'
import welfare7 from 'assets/about/3/welfare/7.png'
import welfare8 from 'assets/about/3/welfare/8.png'
import welfare9 from 'assets/about/3/welfare/9.png'
import welfare10 from 'assets/about/3/welfare/10.png'

import stotyImg_1 from 'assets/story/-1.png'
import stotyImg0 from 'assets/story/0.png'
import stotyImg1 from 'assets/story/1.png'
import stotyImg2 from 'assets/story/2.png'
import stotyImg3 from 'assets/story/3.png'
import stotyImg4 from 'assets/story/4.png'
import stotyImg5 from 'assets/story/5.png'
import leftIcon from 'assets/story/left_icon.png';
import rightIcon from 'assets/story/right_icon.png';

import workers from 'assets/worker/workers.png'


import Development from 'page/Japan/component/development/DevelopmentComponent';
import Brand from 'page/Japan/component/brand/BrandComponent';

import { useState, useEffect, createRef, useRef } from 'react'
import History from './component/history/HistoryComponent';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

const values = [value1, value2, value3, value4, value5],
    jobs = [job1, job2, job3, job4],
    hoverImgs = [hover_job1, hover_job2, hover_job3, hover_job4],
    welfares = [welfare1, welfare2, welfare3, welfare4, welfare5, welfare6, welfare7, welfare8, welfare9, welfare10],
    cultures = [culture1, culture2, culture3, culture4, culture5],
    stotyImgs = [stotyImg_1,stotyImg0, stotyImg1, stotyImg2, stotyImg3, stotyImg4, stotyImg5];


const About = (props) => {
    const { clickMenu, showConsultModal } = props;
    const width = window.innerWidth;
    const [x, setX] = useState(1);
    const swiper = useRef()

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 3000);

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);
    return (
        <div>
            <Education />

            <History />

            <Brand />
            {/* <Transfer text1='了解更多' text2='咨询专业老师' showConsultModal={showConsultModal} /> */}

            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='冲冲鸭STORY'
                subtitle='冲冲鸭故事'
                aline='center' />


            <div style={{
                display: width > 820 ? 'block' : 'none'
            }} className={style.story_box}>
                <img className={style.left_btn} src={leftIcon} alt='' onClick={() => swiper.current.swiper.slidePrev()} />
                <div className={style.storys}>
                    <Swiper
                        ref={swiper}
                        slidesPerView={3}
                    >
                        {stotyImgs.map((item, i) => {
                            const click = () => {
                                switch (i) {
                                    case 0:
                                        window.open('https://mp.weixin.qq.com/s/FwEJO914mpdQyrDNAGYGtQ', '_blank')
                                        break;
                                    case 1:
                                        window.open('https://mp.weixin.qq.com/s/qCpr40XbfA16wt87X74gGw', '_blank')
                                        break;
                                    case 2:
                                        window.open('https://mp.weixin.qq.com/s/DfpPHM2jrj8B31NgeXR9Cg', '_blank')
                                        break;
                                    case 3:
                                        window.open('https://mp.weixin.qq.com/s/pG0z2273TTciN526Tx8DaA', '_blank')
                                        break;
                                    case 4:
                                        window.open('https://mp.weixin.qq.com/s/f4tBYT3XUBojBjOtPY6Zhw', '_blank')
                                        break;
                                    case 5:
                                        window.open('https://www.xiaohongshu.com/explore/652ce1b4000000002101f80f?app_platform=android&app_version=8.9.0&author_share=1&ignoreEngage=true&share_from_user_hidden=true&type=normal&xhsshare=CopyLink&appuid=626b7ee1000000002102b4b6&apptime=1698117266', '_blank')
                                        break;
                                    case 6:
                                        window.open('https://mp.weixin.qq.com/s/AMWK8lKHfpVY_0qMZML_jw', '_blank')
                                        break;
                                    default:
                                        break;
                                }
                            }
                            return <SwiperSlide key={i} style={{
                                cursor: 'pointer'
                            }}>
                                <img src={item} alt='' onClick={click} />
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <img className={style.right_btn} src={rightIcon} alt='' onClick={() => swiper.current.swiper.slideNext()} />
            </div>

            <div style={{
                display: width > 820 ? 'none' : 'flex'
            }} className={style.story_box}>
                {stotyImgs.map((item, i) => {
                    const click = () => {
                        switch (i) {
                            case 0:
                                window.open('https://mp.weixin.qq.com/s/DfpPHM2jrj8B31NgeXR9Cg', '_blank')
                                break;
                            case 1:
                                window.open('https://mp.weixin.qq.com/s/pG0z2273TTciN526Tx8DaA', '_blank')
                                break;
                            case 2:
                                window.open('https://mp.weixin.qq.com/s/f4tBYT3XUBojBjOtPY6Zhw', '_blank')
                                break;
                            case 3:
                                window.open('https://www.xiaohongshu.com/explore/652ce1b4000000002101f80f?app_platform=android&app_version=8.9.0&author_share=1&ignoreEngage=true&share_from_user_hidden=true&type=normal&xhsshare=CopyLink&appuid=626b7ee1000000002102b4b6&apptime=1698117266', '_blank')
                                break;
                            case 4:
                                window.open('https://mp.weixin.qq.com/s/AMWK8lKHfpVY_0qMZML_jw', '_blank')
                                break;
                            default:
                                break;
                        }
                    }
                    return <img src={item} alt='' onClick={click} />
                })}
            </div>
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title='企业文化'
                subtitle='使命 愿景 宗旨 理念 目标'
                aline='center' />

            <div className={style.culture_box}>
                {cultures.map((item, i) => {
                    return <div className={style.culture} key={i}>
                        <img alt='' src={item} />
                    </div>
                })}
            </div>

            <AboutChild
                title='品牌核心价值'
                subtitle='诚信负责'
                imgs={values}
            />



            <AboutChild
                title='加入冲鸭'
                subtitle='冲鸭友爱大家庭'
                imgs={jobs}
                hoverImgs={hoverImgs}
            />

            <div className={style.title_box}>
                <img src={welfareTitle} alt='' />
            </div>

            <div className={style.welfare_box}>
                {welfares.map((item, i) => {
                    return <div className={style.welfare} key={i}>
                        <img alt='' src={item} />
                    </div>
                })}
            </div>
            {/* <Development clickMenu={clickMenu} /> */}

            <div>
                {/* <Title className='worker_title' title='関連報道/パートナーシップ' subtitle='媒体报道/合作伙伴' mtX='13px' aline='center' />

                <div className='workers' style={{
                    backgroundImage: `url(${workers})`,
                    backgroundPositionX: `-${width > 820 ? x * 300 : x * 80}px`
                }}></div> */}
                <Footer type='about' />
            </div>
        </div>
    );
};

export default About;