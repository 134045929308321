import Historycard from '../historycard/HistorycardComponent';
import style from './HistoryComponent.module.css';

import img_1 from 'assets/about/history/pc/-1.png';
import img_2 from 'assets/about/history/pc/-2.png';

import img0 from 'assets/about/history/pc/0.png';
import img1 from 'assets/about/history/pc/1.png';
import img2 from 'assets/about/history/pc/2.png';
import img3 from 'assets/about/history/pc/3.png';
import img4 from 'assets/about/history/pc/4.png';
import img5 from 'assets/about/history/pc/5.png';
import img6 from 'assets/about/history/pc/6.png';
import img7 from 'assets/about/history/pc/7.png';
import img8 from 'assets/about/history/pc/8.png';
import img9 from 'assets/about/history/pc/9.png';

import img_1M from 'assets/about/history/mobile/-1.png';
import img_2M from 'assets/about/history/mobile/-2.png';
import img0M from 'assets/about/history/mobile/0.png';
import img1M from 'assets/about/history/mobile/1.png';
import img2M from 'assets/about/history/mobile/2.png';
import img3M from 'assets/about/history/mobile/3.png';
import img4M from 'assets/about/history/mobile/4.png';
import img5M from 'assets/about/history/mobile/5.png';
import img6M from 'assets/about/history/mobile/6.png';
import img7M from 'assets/about/history/mobile/7.png';
import img8M from 'assets/about/history/mobile/8.png';
import img9M from 'assets/about/history/mobile/9.png';

import title from 'assets/about/title/title.png';

import { useRef, useEffect, useState } from 'react'

const width = window.innerWidth;
const imgArr = width > 820 ? [img_2, img_1, img0, img1, img2, img3, img4, img5, img6, img7, img8, img9] : [img_2M, img_1M, img0M, img1M, img2M, img3M, img4M, img5M, img6M, img7M, img8M, img9M];
const History = (props) => {
    const { } = props;
    const { hasComputedHeight, setHasComputedHeight } = useState(false)
    const sourceElementRef = useRef(null);
    const targetElementRef = useRef(null);

    useEffect(() => {
        // 获取源元素的高度
        const sourceElement = sourceElementRef.current;
        const targetElement = targetElementRef.current;
        if (sourceElement && targetElement) {
            const sourceElementHeight = sourceElement.clientHeight;
            console.log(sourceElementHeight);
            // 设置目标元素的高度
            targetElement.style.height = `${sourceElementHeight}px`;
            console.log(targetElement);
        }
    }, []);


    return (
        <div className={style.box} >
            <div className={style.title}>
                <img src={title} alt='' />
            </div>
            <div className={style.container}>
                <Historycard img={width > 820 ? img_2 : img_2M} time='2024' month='（10月）' content='冲鸭教育再度联动长沙萌卡漫展，实现了展位规模的全面升级。主展位面积翻倍增长，并特设三楼教室风格拍照区，展现小语种教育与动漫文化的精彩碰撞。此外，团队精心策划了一系列Cosplay表演，挑选了《甄嬛传》与《黑执事》这两部在二次元世界及年轻群体中极具影响力的作品进行角色扮演，同时开设了趣味公开课，吸引了众多游客目光，生动诠释“好玩实用”的教学理念。此次联动不仅显著提升了冲鸭教育的品牌知名度，更使其深入洞察年轻消费者的需求。通过与漫展观众的亲密互动，冲鸭教育能够精准捕捉市场动向与消费者心理，为后续的课程研发与服务策略优化提供了有力支持。' />
                <Historycard img={width > 820 ? img_1 : img_1M} time='2024' month='（5月）' content='新地点新征程，冲鸭教育人才队伍不断壮大，我们搬进全新的办公室，讲师房数量共计20余个，实现每位教师独立授课空间，触达海内外更多学员，提供更为专注、高效的学习体验。' />
                <Historycard img={width > 820 ? img0 : img0M} time='2024' month='（5月）' content='2024年(5月)再度与萌卡漫展合作，冲鸭教育以一系列的周边产品和趣味互动游戏，吸引上千人参与，为前来的二次元文化爱好者带来了别具一格的游玩体验。此次亮相不仅展示了冲鸭教育“好玩实用”的教育理念，也进一步扩大了其在年轻群体中的品牌影响力。' />
                <Historycard img={width > 820 ? img1 : img1M} time='2023' month='（12月）' content='冲鸭教育受邀参与央广网”声彻中国“教育年度大会，并荣获“2023年度国际教育领军品牌”奖项，这一奖项，不仅是对冲鸭教育打造高质量国际语言教学的肯定，更彰显了冲鸭教育在国际语言教育领域耕耘的认可。' />
                <Historycard img={width > 820 ? img2 : img2M} time='2023' month='（10月）' content='冲鸭教育联动萌卡动漫展首次加入线下活动，冲鸭教育作为漫展活动教育行业一枝独秀火爆现场，争相与吉祥物冲冲鸭合影拍照，参与现场集邮打卡活动。' />
                <Historycard img={width > 820 ? img3 : img3M} time='2023' month='（9月）' content='冲鸭为小语种人群发布韩语学习工具——冲鸭韩语APP。冲鸭韩语以40音学习为基础，为韩语入门做好铺垫。提供基础短语单词、查词工具等，更有韩语发音技巧，帮助学会地道发音。' />
                <Historycard img={width > 820 ? img4 : img4M} time='2023' month='（4月）' content='冲鸭教育荣获AAA级信用企业。AAA认证是信用标准化领域中等级最高的认证，这项荣誉反应冲鸭教育具有很强的发展潜力、持续经营状况和盈利能力。' />
                <Historycard img={width > 820 ? img5 : img5M} time='2023' month='（2月）' content='冲鸭教育推出IP吉祥物——冲冲鸭，IP一经推出便深受学员喜爱，同年不断推出受大家欢迎的表情包、周边。' />
                <Historycard img={width > 820 ? img6 : img6M} time='2022' month='（12月）' content='受邀腾讯「回响中国」 教育高峰论坛，获得「2022知名在线教育品牌」奖' />
                <Historycard img={width > 820 ? img7 : img7M} time='2022' month='（11月）' content='冲鸭教育达成与早稻田大学等高校学友会联名举办配音比赛，歌唱比赛等。并积极拓展与多家语言学校合作，为学员提供留学咨询指导服务。' />
                <Historycard img={width > 820 ? img8 : img8M} time='2022' month='（6月）' content='冲鸭发布面向日语入门的学习工具——冲鸭日语APP。内容涵盖50音基础学习、日常短语单词、查词翻译、趣味视频、提分攻略。从冲鸭日语APP开始，好玩实用的日语学习的大门由此打开。' />
                <Historycard img={width > 820 ? img9 : img9M} time='2022' month='（1月）' content='正式以冲鸭教育为名，开设小语种科目课程。课程主打好玩实用，以海量外教口语场景课为依托，应用高校必修、选修学分制模式，重视学员听说读写综合能力全面提升。' />

            </div>
        </div >
    );
};

export default History;