import style from './AboutChildComponent.module.css';
import Title from 'component/title/TitleComponent';
import ImgBox from 'component/imgBox/ImgBoxComponent';

const AboutChild = (props) => {
    const { title, subtitle, imgs, hoverImgs, isHome } = props;


    const width = window.innerWidth;

    return (
        <div >
            <Title
                className={style.title_box}
                mtX='0.83vw'
                title={title}
                subtitle={subtitle}
                aline='center' />

            <div className={style.img_box} style={{
                width: width > 820 ? '65vw' : !hoverImgs ? '82vw' : '90vw',
                flexWrap: isHome ? 'nowrap' : hoverImgs ? 'nowrap' : 'wrap'
            }}>
                {imgs.map((item, i) => {
                    return <div className={style.single_img} key={i} onClick={() => {
                        if (!hoverImgs) {
                            return;
                        }
                        switch (i) {
                            case 0:
                                window.open('https://www.zhipin.com/gongsi/50f53c17542c2d511XRz0tS4FFU~.html?ka=job-detail-company_custompage')
                                break;
                            case 1:
                                window.open('https://www.zhipin.com/gongsi/50f53c17542c2d511XRz0tS4FFU~.html?ka=job-detail-company_custompage')
                                break;
                            case 2:
                                window.open('https://www.zhipin.com/gongsi/50f53c17542c2d511XRz0tS4FFU~.html?ka=job-detail-company_custompage')
                                break;
                            case 3:
                                window.open('https://www.zhipin.com/gongsi/50f53c17542c2d511XRz0tS4FFU~.html?ka=job-detail-company_custompage')
                                break;
                            default:
                                break;
                        }
                    }}
                        style={{
                            cursor: hoverImgs ? 'pointer' : 'default',
                            width: width > 820 ? 'auto' : isHome ? '21vw' : hoverImgs ? 'auto' : '26vw',
                            flex: width > 820 ? '1' : hoverImgs ? '1' : 'none'
                        }}>
                        {hoverImgs && hoverImgs.length !== 0 ? <ImgBox img={item} hoverImg={hoverImgs[i]} /> : <img src={item} alt='' />}
                    </div>
                })}
            </div>
        </div >
    );
};

export default AboutChild;