import style from './ImgBoxComponent.module.css';

import { useState } from 'react';

const ImgBox = (props) => {
    const { img, hoverImg } = props;
    const [ishover, setHover] = useState(false);


    const handleMouseEnter = (e) => {
        setHover(true);
    };

    const handleMouseLeave = (e) => {
        setHover(false);
    };
    return (
        <div
            onMouseEnter={() => { handleMouseEnter() }}
            onMouseLeave={handleMouseLeave}
        >
            <img src={ishover ? hoverImg : img} alt='' />
        </div>
    );
};

export default ImgBox;